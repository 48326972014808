import { defineStore } from "pinia";
import { TemplateService } from "@/services/template.service";
import { useAuthStore } from "@/store/modules/auth";

export const usePrintStore = defineStore("print", {
  state: () => ({
    printBirthPage: null as any,
    printDeathPage: null as any
  }),

  getters: {
    getBirthPrintPage: (state) => state.printBirthPage,
    getDeathPrintPage: (state) => state.printDeathPage
  },

  actions: {
    loadPrintPage(payload: string, type: string): any {
      return TemplateService.getInstance(useAuthStore().getAccessToken)
        .getInstancePrintPage(payload)
        .then((value) => {
          if (value.data) {
            if (type === "birth") this.printBirthPage = value.data;
            else this.printDeathPage = value.data;
            return value.data;
          } else return null;
        });
    }
  },
});