<template>
	<div
		class="card p-3"
		@click="loginWithKeycloak()">
		<div class="flex justify-content-center">
			<div class="card-image p-3">
				<img
					alt="user header"
					class="icon-style"
					:src="iconName" />
			</div>
		</div>
		<div class="app-card-title label-row">
			{{ applabelname }}
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";

	export default defineComponent({
		name: "AppCard",
		props: {
			iconName: { type: String, default: "" },
			applabelname: String,
			link: { type: String, default: "" },
		},
		methods: {
			loginWithKeycloak() {
				(this as any).$router.push({ path: this.link || "/" });
			},
		},
	});
</script>

<style src="./card.scss" lang="scss" scoped />
