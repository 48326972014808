import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_birth_registration = _resolveComponent("birth-registration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title-padding m-0" }, [
        _createElementVNode("h1", { class: "espace-header" }, "Liste des déclarations de naissance")
      ], -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_birth_registration)
      ])
    ])
  ]))
}