import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "grid px-2 align-items-center" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "col-12 md:col" }
const _hoisted_5 = ["placeholder", "id", "type", "autocomplete", "disabled"]
const _hoisted_6 = {
  key: 0,
  class: "flex justify-content-end px-2 error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.label,
        class: "col-12 mb-2 md:col md:mb-0"
      }, _toDisplayString(_ctx.label), 9, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
          placeholder: _ctx.placeholder,
          id: _ctx.label,
          type: _ctx.type ? _ctx.type : 'text',
          autocomplete: _ctx.autocomplete,
          disabled: _ctx.disabled,
          class: "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        }, null, 40, _hoisted_5), [
          [_vModelDynamic, _ctx.internalValue]
        ])
      ])
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}