class ErrorManager {
    private static instance: ErrorManager;
    public errorMessage: string | null = null;

    public static getInstance(): ErrorManager {
        if (!ErrorManager.instance) {
            ErrorManager.instance = new ErrorManager();
        }
        return ErrorManager.instance;
    }

    public setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    public getErrorMessage(): string | null {
        return this.errorMessage;
    }

    public clearErrorMessage() {
        this.errorMessage = null;
    }
}

export default ErrorManager.getInstance();
