import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap align-items-center justify-content-between gap-2" }
const _hoisted_2 = { class: "text-xl text-900 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconField = _resolveComponent("IconField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.tableData,
    filters: _ctx.filters,
    globalFilterFields: _ctx.globalFilterFields,
    onRowClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onRowClicked($event))),
    filterDisplay: "row",
    tableStyle: "min-width: 50rem",
    scrollHeight: "500px"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(`${_ctx.tableTitle} (${_ctx.tableData ? _ctx.tableData.length : 0})`), 1),
        _createVNode(_component_IconField, null, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: "Recherche",
              modelValue: _ctx.searchText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.search(_ctx.searchText)))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
        return (_openBlock(), _createBlock(_component_Column, {
          field: col.field,
          header: col.header,
          key: col.field
        }, _createSlots({ _: 2 }, [
          (col.field === 'actions')
            ? {
                name: "body",
                fn: _withCtx((slotProps) => [
                  _createVNode(_component_Button, {
                    severity: "help",
                    onClick: ($event: any) => (_ctx.declaration(slotProps.data)),
                    icon: "pi pi-pencil",
                    rounded: "",
                    raised: ""
                  }, null, 8, ["onClick"])
                ]),
                key: "0"
              }
            : (col.field === 'created_at')
              ? {
                  name: "body",
                  fn: _withCtx((slotProps) => [
                    _createTextVNode(_toDisplayString(_ctx.formatLocaleDate(slotProps.data.created_at)), 1)
                  ]),
                  key: "1"
                }
              : (col.field === 'mother_last_name')
                ? {
                    name: "body",
                    fn: _withCtx((slotProps) => [
                      _createTextVNode(_toDisplayString(slotProps.data.mother_last_name +
					" " +
					slotProps.data.mother_first_name), 1)
                    ]),
                    key: "2"
                  }
                : (col.field === 'last_name_deceased')
                  ? {
                      name: "body",
                      fn: _withCtx((slotProps) => [
                        _createTextVNode(_toDisplayString(slotProps.data.last_name_deceased +
					" " +
					slotProps.data.first_name_deceased), 1)
                      ]),
                      key: "3"
                    }
                  : (col.field === 'declarant')
                    ? {
                        name: "body",
                        fn: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(_ctx.agentName(slotProps.data.declarant)), 1)
                        ]),
                        key: "4"
                      }
                    : (col.field === 'document')
                      ? {
                          name: "body",
                          fn: _withCtx((slotProps) => [
                            _createVNode(_component_Button, {
                              class: "p-2",
                              severity: "success",
                              onClick: _withModifiers(($event: any) => (_ctx.openInNewBlank(slotProps.data)), ["prevent"]),
                              rounded: "",
                              raised: ""
                            }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createElementVNode("span", {
                                  class: "material-icons",
                                  style: {"font-size":"18px"}
                                }, "print", -1)
                              ])),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          key: "5"
                        }
                      : undefined
        ]), 1032, ["field", "header"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value", "filters", "globalFilterFields"]))
}