import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "flex align" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
      "aria-haspopup": "true",
      "aria-controls": "overlay_menu",
      class: "w-full p-link flex align-items-center"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.username), 1),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "material-icons" }, "arrow_drop_down", -1))
      ])
    ]),
    _createVNode(_component_Menu, {
      ref: "menu",
      id: "overlay_menu",
      class: "user-navbar-overlay-menu",
      popup: true
    }, {
      end: _withCtx(() => [
        (_ctx.currentRouteName == _ctx.workingSpace)
          ? (_openBlock(), _createElementBlock("button", _hoisted_4, _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "pi pi-sign-out" }, null, -1),
              _createElementVNode("span", { class: "ml-2" }, "Mon profil", -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onLogout && _ctx.onLogout(...args)), ["prevent"]))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "pi pi-sign-out" }, null, -1),
          _createElementVNode("span", { class: "ml-2" }, "Se déconnecter", -1)
        ]))
      ]),
      _: 1
    }, 512)
  ]))
}