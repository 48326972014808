import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, isRef as _isRef, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_3 = { class: "title-padding m-0" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "form-section" }
const _hoisted_6 = {
  class: "formgrid grid mx-2",
  style: {"margin-top":"2rem"}
}
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = { class: "field grid px-2" }
const _hoisted_9 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_10 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_11 = { class: "field col" }
const _hoisted_12 = { class: "field grid px-2" }
const _hoisted_13 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_14 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_15 = { class: "form-section" }
const _hoisted_16 = {
  class: "formgrid grid mx-2",
  style: {"margin-top":"2rem"}
}
const _hoisted_17 = { class: "field col" }
const _hoisted_18 = { class: "field col" }
const _hoisted_19 = { class: "form-section" }
const _hoisted_20 = {
  class: "formgrid grid mx-2",
  style: {"margin-top":"2rem"}
}
const _hoisted_21 = { class: "field col" }
const _hoisted_22 = { class: "field col" }
const _hoisted_23 = { class: "field grid px-2 py-1" }
const _hoisted_24 = {
  class: "col-12 md:col flex flex-wrap gap-3",
  style: {"justify-content":"end"}
}
const _hoisted_25 = { class: "flex" }
const _hoisted_26 = { class: "flex" }
const _hoisted_27 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_28 = { class: "field grid px-2" }
const _hoisted_29 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_30 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_31 = { class: "my-4 send-declaration-btn-container" }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { class: "mt-4" }
const _hoisted_34 = {
  key: 3,
  class: "bg-default-green tracked_code_container"
}
const _hoisted_35 = { class: "text-white tracked_text" }
const _hoisted_36 = { class: "code-color" }
const _hoisted_37 = { class: "mt-4 send-declaration-btn-container" }

import { ref, watch } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import { useRouter } from 'vue-router';
	import { useToast } from "primevue/usetoast";
	import ErrorManager from '../../errorManager';
	import moment from 'moment';

	
	import { defineComponent } from "vue";
	import PInput from "@/components/forms/p-input.vue";
	import { useAuthStore } from "@/store/modules/auth";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { useDeathStore } from "@/store/modules/death";
	import { agentStore } from "@/store/modules/agent";
	import { AgentOption } from "@/models/agent/agent";
	import { DeathDeclaration } from "@/models/declaration/death-declaration";
	import { deathTemplateId } from "@/app.config";
	import { dataMixin } from "@/mixins/data.mixins";
	import { dateMixin } from "@/mixins/date.mixins";
    import { usePrintStore } from "@/store/modules/print-process";
	const __default__ = defineComponent({
		name: "DeathPage",
		components: { PInput },
		mixins: [dataMixin, dateMixin],
		data() {
			const minDate = new Date();
			minDate.setDate(minDate.getDate() - 14);

			const maxDate = new Date();
			return {
				maxDate: maxDate,
				minDate: minDate,
				autoFilteredValue: [] as Array<object>,
				authStore: useAuthStore(),
				partnerStore: espacePartenaireStore(),
				agentStore: agentStore(),
			};
		},
		methods: {
			searchUser(event) {
				if (!event.query.trim().length) {
					this.autoFilteredValue = [...this.agentStore.agentOptions];
				} else {
					this.autoFilteredValue = this.agentStore.agentOptions.filter((user) => {
						return user.name.toLowerCase().includes(event.query.toLowerCase());
					});
				}
			},
			loadAgents() {
				if(!this.agentStore.getAgents) this.agentStore.loadAgents();
			}
		},
		beforeMount() {
			this.loadAgents()
			if(!usePrintStore().getDeathPrintPage) usePrintStore().loadPrintPage(deathTemplateId, 'death')
		},
	});


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

	const schema = yup.object({
		chief_service_id: yup
			.string()
			.required("Veuillez choisir le chef service")
			.label("Chef service"),
		secret_code: yup
			.string()
			.required("Le code secret est requis")
			.label("Code secret"),
		declarant: yup
			.string()
			.required("Veuillez choisir le déclarant")
			.label("Déclarant"),
		applicant_name: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, {
				message: "Le champ ne doit contenir que des lettres et des espaces"
			})
			.required("Le nom et prénom du demandeur sont requis")
			.label("Nom et prénoms du demandeur"),
		applicant_relationship: yup
			.string()
			.required("Ce champ est requis")
			.label("Lien de parenté"),
		applicant_telephone: yup
			.string()
			.required("Le numéro de téléphone est requis")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("N° de téléphone"),
		contact_phone_number: yup
			.string()
			.required("Veuillez renseigner le numero de téléphone")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Téléphone contact"),
		last_name_deceased: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, "Le champ ne doit contenir que des lettres")
			.required("Le nom du defunt est requis")
			.label("Nom du defunt"),
		first_name_deceased: yup
			.string()
			.matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, "Le champ ne doit contenir que des lettres")
			.required("Le prénom du defunt est requis")
			.label("Prénoms du defunt"),
		age_deceased: yup
			.string()
			.matches(/^[0-9]+$/, "L'âge du défunt doit être un nombre")
			.required("L'age du defunt est requis")
			.label("Age du defunt"),
		function_of_deceased: yup
			.string()
			.required("La fonction du defunt est requies")
			.label("Profession du defunt"),
		death_date: yup
			.string()
			.required("La date de décès est requise")
			.label("Date de décès"),
		reason_for_admission: yup
			.string()
			.required("La cause de décès est requise")
			.label("Cause d'admission"),
		sex: yup
			.string()
			.required("Le genre du defunt est requis")
			.label("Genre"),
		neighborhood: yup
			.string()
			.required("Veuillez renseigner le quartier du defunt")
			.label("Quartier du defunt"),
		address: yup
			.string()
			.required("Veuillez renseigner l'adresse du defunt")
			.label("Adresse du defunt"),
	});

	const { defineField, handleSubmit, errors } = useForm({
		validationSchema: schema,
	});

	const [service_id] = defineField("service_id");
	const [secret_code] = defineField("secret_code");
	const [declarant] = defineField("declarant");
	const [chief_service_id] = defineField("chief_service_id");
	const [applicant_name] = defineField("applicant_name");
	const [applicant_telephone] = defineField("applicant_telephone");
	const [applicant_identifier] = defineField("applicant_identifier");
	const [applicant_relationship] = defineField("applicant_relationship");
	const [last_name_deceased] = defineField("last_name_deceased");
	const [first_name_deceased] = defineField("first_name_deceased");
	const [age_deceased] = defineField("age_deceased");
	const [function_of_deceased] = defineField("function_of_deceased");
	const [contact_phone_number] = defineField("contact_phone_number");
	const [other_contact] = defineField("other_contact");
	const [death_date] = defineField("death_date");
	const [reason_for_admission] = defineField("reason_for_admission");
	const [sex] = defineField("sex");
	const [neighborhood] = defineField("neighborhood");
	const [address] = defineField("address");

	const chief_service_select = ref({} as AgentOption);
	const declarant_select = ref({} as AgentOption);
	const submitted = ref(false);
	const isLoading = ref(false);
	const tracked_code = ref('');
	const dataResponse = ref(null);
	const mediaUri = ref('');
	const router = useRouter();
	const toast = useToast();

	watch(
		() => chief_service_select.value,
		(newVal) => {
			if(newVal?.id) chief_service_id.value = newVal.id;
			else chief_service_id.value = " "
		}
	);

	watch(
		() => declarant_select.value,
		(newVal) => {
			declarant.value = newVal.id;
		}
	);


	const onSubmit = handleSubmit((values) => {
		if (
			isLoading.value ||
			new Date(values.death_date) > new Date()
		) {
			toast.add({ severity: 'error', summary: 'Message', detail: 'Veuillez renseigner une date de décès valide', life: 8000 });
			return;
		}

		isLoading.value = true;
		const deathDeclaration = values as DeathDeclaration;
		deathDeclaration.agent_function = 'Agent';
		deathDeclaration.applicant_identifier = values.applicant_identifier ? values.applicant_identifier : '';
		deathDeclaration.health_center = useAuthStore().getUserInfo.health_center;
		deathDeclaration.health_center_code = useAuthStore().getUserInfo.health_center_code;
		deathDeclaration.agent_function = dataMixin.methods.agentFunction(deathDeclaration.chief_service_id);
		deathDeclaration.service_id = dataMixin.methods.agentServiceId(deathDeclaration.chief_service_id);
		deathDeclaration.death_date = dateMixin.methods.resetTimeToZero(values.death_date);
				
		const array = [] as Array<DeathDeclaration>;
		array.push(deathDeclaration);
		useDeathStore().sendDeathDeclaration(array)
		    .then((response) => {
				submitted.value = true;
				tracked_code.value = response.tracked_code;
				dataResponse.value = response;
				toast.add({ severity: 'success', summary: 'Message', detail: "Votre document a été généré avec succès. Veuillez l'imprimer dans le nouvel onglet!", life: 8000 });
				generateDoc(response);
			})
			.catch((error) => {
				const errorMessage = ErrorManager.getErrorMessage();
				toast.add({ severity: 'error', summary: 'Message', detail: errorMessage, life: 8000 });
				submitted.value = false;
			})
			.finally(() => {
				isLoading.value = false;
			});
	});

	const generateDoc = (response: DeathDeclaration) => {
		const variables = {
			health_center_name: response.health_center,
			service: dataMixin.methods.agentService(response.chief_service),
			tracked_code: response.tracked_code,
			defunt_name: response.last_name_deceased + " " + response.first_name_deceased,
			defunt_age: response.age_deceased,
			defunt_function: response.function_of_deceased,
			town: espacePartenaireStore().getHealthCenter.town,
			death_date: dateMixin.methods.formatDate(response.death_date),
			district: espacePartenaireStore().getHealthCenter.district,
			township: espacePartenaireStore().getHealthCenter.township,
			bp: espacePartenaireStore().getHealthCenter.postal_code,
			center_phone: espacePartenaireStore().getHealthCenter.mobile,
			created_at: dateMixin.methods.formatDate(response.created_at),
			chief_service: dataMixin.methods.agentName(response.chief_service),
			function: dataMixin.methods.agentFunction(response.chief_service),
		};
		const windowPrint = window.open('', '', 'width=800,height=600');
		windowPrint?.document.write(processTemplate(
			usePrintStore().getDeathPrintPage, 
			{
				...variables
			}
		));
		windowPrint?.document.close();
		windowPrint?.focus();
		windowPrint?.print();
	}
	const processTemplate = (template: string, variables: Record<string, string>) => {
		return template.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
			return variables[variable] || '';
		});
	};

	const goToHome = (type: boolean) => {
		if (type) {
			router.push({ path: "/accueil" });
		} else {	
			service_id.value = "",
			chief_service_select.value = {};
			secret_code.value = "",
			applicant_name.value = "",
			applicant_telephone.value = "",
			applicant_identifier.value = "",
			applicant_relationship.value = "",
			last_name_deceased.value = "",
			first_name_deceased.value = "",
			age_deceased.value = 0,
			function_of_deceased.value = "",
			contact_phone_number.value = "",
			other_contact.value = "",
			death_date.value = " ",
			reason_for_admission.value = "",
			sex.value = " ",
			neighborhood.value = "",
			address.value = "",

			submitted.value = false;
			dataResponse.value = null;
		}
	};


return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toast),
    _createElementVNode("div", null, [
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"50px","height":"50px"},
              strokeWidth: "5",
              fill: "var(--surface-ground)",
              animationDuration: ".5s",
              "aria-label": "Custom ProgressSpinner"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          class: "flex arrow_back_div mb-4"
        }, _cache[25] || (_cache[25] = [
          _createElementVNode("span", { class: "material-icons arrow_back_class mr-2" }, "arrow_back", -1),
          _createElementVNode("span", { class: "form-header" }, "Retour", -1)
        ])),
        _cache[26] || (_cache[26] = _createElementVNode("h1", { class: "espace-header" }, "Déclaration de décès", -1))
      ]),
      _cache[38] || (_cache[38] = _createElementVNode("div", { class: "form-header" }, " Ce formulaire doit être rempli par un professionnel de la santé affilié à la télédéclaration ", -1)),
      (!submitted.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("form", {
              onSubmit: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _cache[29] || (_cache[29] = _createElementVNode("div", { class: "section-header" }, "Informations du centre de santé", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(PInput, {
                      label: "Centre de santé",
                      value: _unref(useAuthStore)().getUserInfo.health_center
                    }, null, 8, ["value"]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[27] || (_cache[27] = _createElementVNode("label", {
                        for: "chef",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Chef service", -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_AutoComplete, {
                          class: "w-full",
                          placeholder: "Selectionnez...",
                          id: "id",
                          dropdown: true,
                          multiple: false,
                          modelValue: chief_service_select.value,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((chief_service_select).value = $event)),
                          suggestions: _ctx.autoFilteredValue,
                          onComplete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchUser($event))),
                          field: "name"
                        }, null, 8, ["modelValue", "suggestions"]),
                        _createElementVNode("small", _hoisted_10, _toDisplayString(_unref(errors).chief_service_id), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[28] || (_cache[28] = _createElementVNode("label", {
                        for: "declarant",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Déclarant", -1)),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_AutoComplete, {
                          class: "w-full",
                          placeholder: "Selectionnez...",
                          id: "value",
                          dropdown: true,
                          multiple: false,
                          modelValue: declarant_select.value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((declarant_select).value = $event)),
                          suggestions: _ctx.autoFilteredValue,
                          onComplete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchUser($event))),
                          field: "name"
                        }, null, 8, ["modelValue", "suggestions"]),
                        _createElementVNode("small", _hoisted_14, _toDisplayString(_unref(errors).declarant), 1)
                      ])
                    ]),
                    _createVNode(PInput, {
                      label: "Code secret",
                      autocomplete: "off",
                      value: _unref(secret_code),
                      modelValue: _unref(secret_code),
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(secret_code) ? (secret_code).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).secret_code }),
                      field: "secret_code"
                    }, null, 8, ["value", "modelValue", "class"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "section-header" }, "Informations sur le demandeur", -1)),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(PInput, {
                      label: "Nom et prénoms du demandeur",
                      value: _unref(applicant_name),
                      modelValue: _unref(applicant_name),
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(applicant_name) ? (applicant_name).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).applicant_name }),
                      field: "applicant_name"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Lien de parenté",
                      value: _unref(applicant_relationship),
                      modelValue: _unref(applicant_relationship),
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(applicant_relationship) ? (applicant_relationship).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).applicant_relationship }),
                      field: "applicant_relationship"
                    }, null, 8, ["value", "modelValue", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(PInput, {
                      label: "N° d'identité",
                      value: _unref(applicant_identifier),
                      modelValue: _unref(applicant_identifier),
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(applicant_identifier) ? (applicant_identifier).value = $event : null)),
                      field: "applicant_identifier"
                    }, null, 8, ["value", "modelValue"]),
                    _createVNode(PInput, {
                      label: "N° de téléphone",
                      value: _unref(applicant_telephone),
                      modelValue: _unref(applicant_telephone),
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(applicant_telephone) ? (applicant_telephone).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).applicant_telephone }),
                      field: "applicant_telephone"
                    }, null, 8, ["value", "modelValue", "class"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[35] || (_cache[35] = _createElementVNode("div", { class: "section-header" }, "Informations sur le décès", -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(PInput, {
                      label: "Nom du defunt",
                      value: _unref(last_name_deceased),
                      modelValue: _unref(last_name_deceased),
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(last_name_deceased) ? (last_name_deceased).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).last_name_deceased }),
                      field: "last_name_deceased"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Age du defunt",
                      placeholder: "0",
                      value: _unref(age_deceased),
                      modelValue: _unref(age_deceased),
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_isRef(age_deceased) ? (age_deceased).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).age_deceased }),
                      field: "age_deceased"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Profession du defunt",
                      value: _unref(function_of_deceased),
                      modelValue: _unref(function_of_deceased),
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_isRef(function_of_deceased) ? (function_of_deceased).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).function_of_deceased }),
                      field: "function_of_deceased"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Téléphone contact",
                      value: _unref(contact_phone_number),
                      modelValue: _unref(contact_phone_number),
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_isRef(contact_phone_number) ? (contact_phone_number).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).contact_phone_number }),
                      field: "contact_phone_number"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Quartier du defunt",
                      value: _unref(neighborhood),
                      modelValue: _unref(neighborhood),
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_isRef(neighborhood) ? (neighborhood).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).neighborhood }),
                      field: "neighborhood"
                    }, null, 8, ["value", "modelValue", "class"])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(PInput, {
                      label: "Prénoms du defunt",
                      value: _unref(first_name_deceased),
                      modelValue: _unref(first_name_deceased),
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_isRef(first_name_deceased) ? (first_name_deceased).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).first_name_deceased }),
                      field: "first_name_deceased"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[33] || (_cache[33] = _createElementVNode("label", {
                        for: "sexe",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Genre", -1)),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_RadioButton, {
                            inputId: "male",
                            name: "Masculin",
                            value: "male",
                            modelValue: _unref(sex),
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_isRef(sex) ? (sex).value = $event : null))
                          }, null, 8, ["modelValue"]),
                          _cache[31] || (_cache[31] = _createElementVNode("label", {
                            for: "male",
                            class: "ml-2"
                          }, "Masculin", -1))
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_component_RadioButton, {
                            inputId: "female",
                            name: "Feminin",
                            value: "female",
                            modelValue: _unref(sex),
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_isRef(sex) ? (sex).value = $event : null))
                          }, null, 8, ["modelValue"]),
                          _cache[32] || (_cache[32] = _createElementVNode("label", {
                            for: "female",
                            class: "ml-2"
                          }, "Feminin", -1))
                        ]),
                        _createElementVNode("small", _hoisted_27, _toDisplayString(_unref(errors).sex), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _cache[34] || (_cache[34] = _createElementVNode("label", {
                        for: "date",
                        class: "col-12 mb-2 md:col md:mb-0"
                      }, "Date de décès", -1)),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_Calendar, {
                          class: "w-full",
                          showIcon: "",
                          showOnFocus: false,
                          modelValue: _unref(death_date),
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_isRef(death_date) ? (death_date).value = $event : null)),
                          minDate: _ctx.minDate,
                          maxDate: _ctx.maxDate,
                          dateFormat: "dd/mm/yy"
                        }, null, 8, ["modelValue", "minDate", "maxDate"]),
                        _createElementVNode("small", _hoisted_30, _toDisplayString(_unref(errors).death_date), 1)
                      ])
                    ]),
                    _createVNode(PInput, {
                      label: "Cause d'admission",
                      value: _unref(reason_for_admission),
                      modelValue: _unref(reason_for_admission),
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_isRef(reason_for_admission) ? (reason_for_admission).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).reason_for_admission }),
                      field: "reason_for_admission"
                    }, null, 8, ["value", "modelValue", "class"]),
                    _createVNode(PInput, {
                      label: "Adresse du defunt",
                      value: _unref(address),
                      modelValue: _unref(address),
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_isRef(address) ? (address).value = $event : null)),
                      class: _normalizeClass({ 'p-invalid': _unref(errors).address }),
                      field: "address"
                    }, null, 8, ["value", "modelValue", "class"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_Toast),
                _createVNode(_component_Button, {
                  class: "send-declaration-btn",
                  label: "Ajouter",
                  type: "submit"
                })
              ])
            ], 32)
          ]))
        : _createCommentVNode("", true),
      (submitted.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("button", {
                class: "files-btn-class mr-2",
                onClick: _cache[22] || (_cache[22] = ($event: any) => (goToHome(true)))
              }, " Accueil "),
              _createElementVNode("button", {
                class: "files-btn-class",
                onClick: _cache[23] || (_cache[23] = ($event: any) => (goToHome(false)))
              }, " Déclarer un autre décès ")
            ])
          ]))
        : _createCommentVNode("", true),
      (submitted.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _cache[36] || (_cache[36] = _createTextVNode(" Votre déclaration est enregistrée sous le code : ")),
              _createElementVNode("span", _hoisted_36, _toDisplayString(tracked_code.value), 1),
              _cache[37] || (_cache[37] = _createTextVNode(". "))
            ]),
            _createElementVNode("div", _hoisted_37, [
              (dataResponse.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "send-declaration-btn",
                    onClick: _cache[24] || (_cache[24] = ($event: any) => (generateDoc(dataResponse.value)))
                  }, " Télécharger l'attestation "))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})