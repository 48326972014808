import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  key: 0,
  class: "icon-place"
}
const _hoisted_3 = { class: "mx-2 p-0" }
const _hoisted_4 = { class: "flex justify-content-between" }
const _hoisted_5 = {
  key: 0,
  class: "ml-auto mr-3 flex align-items-center justify-content-around"
}
const _hoisted_6 = { class: "structure-class" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_user_dropdown = _resolveComponent("user-dropdown")!
  const _component_Menubar = _resolveComponent("Menubar")!

  return (_openBlock(), _createBlock(_component_Menubar, { class: "layout-topbar" }, {
    start: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.currentRouteName == _ctx.workingSpace)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.currentRouteName != _ctx.workingSpace)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuClickEvent(_ctx.workingSpacePath))),
              class: "mx-2 p-0 flex align-items-center"
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("span", { class: "material-icons mouse-pointer icon-animation p-2" }, "home", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.currentRouteName != _ctx.workingSpace)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1))),
              class: "mx-2 p-0 flex align-items-center"
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("span", { class: "material-icons mouse-pointer icon-animation p-2" }, "arrow_back", -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.displayedName(_ctx.currentRouteName)), 1),
        _createElementVNode("div", {
          class: "align-self-center site-name-class py-0",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.menuClickEvent(_ctx.workingSpacePath)))
        }, _toDisplayString(_ctx.siteName), 1)
      ])
    ]),
    end: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.partnerStore.getHealthCenter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "px-5 py-0",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.menuClickEvent(_ctx.workingSpacePath)))
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.partnerStore.getHealthCenter.health_center_name), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Avatar, {
          image: "https://i.pravatar.cc/200?img=51",
          class: "mr-3",
          size: "large",
          shape: "circle"
        }),
        (_ctx.authStore.getUserInfo)
          ? (_openBlock(), _createBlock(_component_user_dropdown, {
              key: 1,
              workingSpacePath: _ctx.workingSpacePath,
              username: _ctx.authStore.getUserInfo.name
            }, null, 8, ["workingSpacePath", "username"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}