import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "card-image p-3" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "app-card-title label-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card p-3",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loginWithKeycloak()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          alt: "user header",
          class: "icon-style",
          src: _ctx.iconName
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.applabelname), 1)
  ]))
}