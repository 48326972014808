<template>
	<Menubar class="layout-topbar">
		<template #start>
			<div class="flex">
				<div
					v-if="currentRouteName == workingSpace"
					class="icon-place"></div>
				<div
					v-if="currentRouteName != workingSpace"
					@click="menuClickEvent(workingSpacePath)"
					class="mx-2 p-0 flex align-items-center">
					<span class="material-icons mouse-pointer icon-animation p-2"
						>home</span
					>
				</div>
				<div
					v-if="currentRouteName != workingSpace"
					@click="$router.go(-1)"
					class="mx-2 p-0 flex align-items-center">
					<span class="material-icons mouse-pointer icon-animation p-2"
						>arrow_back</span
					>
				</div>
				<div class="mx-2 p-0">
					{{ displayedName(currentRouteName) }}
				</div>
				<div
					class="align-self-center site-name-class py-0"
					@click="menuClickEvent(workingSpacePath)">
					{{ siteName }}
				</div>
			</div>
		</template>
		<template #end>
			<div class="flex justify-content-between">
				<div
					v-if="partnerStore.getHealthCenter"
					class="ml-auto mr-3 flex align-items-center justify-content-around">
					<div
						class="px-5 py-0"
						@click="menuClickEvent(workingSpacePath)">
						<span class="structure-class">{{
							partnerStore.getHealthCenter.health_center_name
						}}</span>
					</div>
				</div>
				<Avatar
					image="https://i.pravatar.cc/200?img=51"
					class="mr-3"
					size="large"
					shape="circle" />
				<user-dropdown
					v-if="authStore.getUserInfo"
					:workingSpacePath="workingSpacePath"
					:username="authStore.getUserInfo.name" />
			</div>
		</template>
	</Menubar>
</template>
<script lang="ts">
	import { defineComponent } from "vue";
	import commonVars from "@/common/common-var";
	import { Utils } from "@/common/utils";
	import { useAuthStore } from "@/store/modules/auth";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import UserDropdown from "./user-dropdown/user-dropdown.vue";
    import { usePrintStore } from "@/store/modules/print-process";
	export default defineComponent({
		name: "NavbarApp",
		components: {
			UserDropdown,
		},
		props: {
			exclude: { type: Array, default: () => [] },
		},
		data() {
			return {
				profilDisplayName: Utils._pageMap.get(commonVars.PROFIL),
				profil: commonVars.PROFIL,
				profilPath: commonVars.PROFIL_PATH,
				workingSpacePath: commonVars.WORKINGSPACE_PATH,
				workingSpace: commonVars.HOME,
				structureName: commonVars.STRUCTURE_NAME,
				siteName: commonVars.SITE_NAME,
				authStore: useAuthStore(),
				partnerStore: espacePartenaireStore(),
			};
		},
		computed: {
			currentRouteName(): string {
				return "this.$route.name!.toString()";
			},
		},
		methods: {
			menuClickEvent(routeName: string) {
				(this as any).$router.push({ path: routeName });
			},
			displayedName(name: string): string | undefined {
				return Utils._pageMap.get(name);
			},
			healthCenterInfos() {
			  if (!this.partnerStore.getHealthCenter) 
				setTimeout(() => {
					this.partnerStore.getHealthCenterInformations(
						this.authStore.getUserInfo.health_center_code
					)
				}, 10);
			}
		},
		beforeMount() {
			if (!this.authStore.getUserInfo) this.authStore.getProfile();
			this.healthCenterInfos()
		},
	});
</script>
<style src="./navbar-app.scss" lang="scss" scoped />
