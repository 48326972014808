export const processManagerApiUrl =
	process.env.VUE_APP_API_HOSPITAL_PARTNERS_URL;
export const siteUrl = process.env.VUE_APP_SITE_URL;
export const keycloackAuthorityUrl =
	process.env.VUE_APP_KEYCLOACK_AUTHORITY_URL;
export const keycloackClientIdUrl = process.env.VUE_APP_KEYCLOACK_CLIENT_ID_URL;
export const documentGeneratorApiUrl =
	process.env.VUE_APP_DOCUMENT_GENERATOR_URL;
export const birthHtmlTemplate =
	process.env.VUE_APP_NAISSANCE_HTML_TEMPLATE_URL;
export const birthCssTemplate =
	process.env.VUE_APP_NAISSANCE_CSS_TEMPLATE_URL;
export const deathHtmlTemplate =
	process.env.VUE_APP_DECES_HTML_TEMPLATE_URL;
export const deathCssTemplate =
	process.env.VUE_APP_DECES_CSS_TEMPLATE_URL;
export const birthTemplateId = process.env.VUE_APP_NAISSANCE_HTML_TEMPLATE_ID_URL;
export const deathTemplateId = process.env.VUE_APP_DECES_HTML_TEMPLATE_ID_URL;

export default {
	processManagerApiUrl,
	siteUrl,
	keycloackAuthorityUrl,
	keycloackClientIdUrl,
	documentGeneratorApiUrl,
	birthHtmlTemplate,
	birthCssTemplate,
	deathHtmlTemplate,
	deathCssTemplate,
	birthTemplateId,
	deathTemplateId
};
