import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "declarations-container" }
const _hoisted_4 = { class: "declarations-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeclarationsList = _resolveComponent("DeclarationsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "pt-4 pb-0" }, [
        _createElementVNode("h1", { class: "page-title" }, "Bienvenue dans votre espace dédié")
      ], -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "declarations-container" }, [
        _createElementVNode("span", {
          class: "espace-title",
          style: {"margin-bottom":"2rem"}
        }, "Gestion des naissances")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DeclarationsList, { cards: _ctx.naissanceList }, null, 8, ["cards"])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "espace-border" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "declarations-container" }, [
        _createElementVNode("span", {
          class: "espace-title",
          style: {"margin-bottom":"2rem"}
        }, "Gestion des décès")
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DeclarationsList, { cards: _ctx.decesList }, null, 8, ["cards"])
      ])
    ])
  ]))
}