import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pwa-container"
}
const _hoisted_2 = { class: "pwa-row" }
const _hoisted_3 = { class: "pwa-col" }
const _hoisted_4 = { class: "pwa-btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!

  return (_ctx.smallScreen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.deferredPrompt)
              ? (_openBlock(), _createBlock(_component_Message, {
                  key: 0,
                  severity: "info",
                  class: "m-0"
                }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createTextVNode(" Installez l’application de la marie Golfe 3 en quelques secondes ! ")),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_Button, {
                        class: "pwa-btn dismiss-btn",
                        label: "Annuler",
                        onClick: _ctx.dismiss
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_Button, {
                        class: "pwa-btn install-btn",
                        label: "Installer",
                        onClick: _ctx.install
                      }, null, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}