import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "spinner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProgressSpinner, {
      style: {"width":"50px","height":"50px"},
      strokeWidth: "5",
      fill: "var(--surface-ground)",
      animationDuration: ".5s",
      "aria-label": "Custom ProgressSpinner"
    })
  ]))
}