<template>
	<div class="agents-page">
		<Toast />
		<div>
			<div class="title-padding m-0">
				<h1 class="espace-header">Déclaration et liste des agents</h1>
			</div>
			<div
				v-if="agentStore().getLoading"
				class="spinner-container">
				<ProgressSpinner
					style="width: 50px; height: 50px"
					strokeWidth="5"
					fill="var(--surface-ground)"
					animationDuration=".5s"
					aria-label="Custom ProgressSpinner" />
			</div>
			<div class="my-3">
				<table-filter
					:canCreate="false"
					v-on:searchText="searchText"
					v-on:declarationForm="declarationForm"
					name="un agent" />
			</div>
			<div
				class="form-section mb-3"
				v-if="declaration">
				<form @submit="onSubmit">
					<div class="formgrid grid mx-2 mb-0 mt-4">
						<div class="field col">
							<PInput
								label="Nom complet"
								v-model="name"
								:value="name"
								type="text"
								:class="{ 'p-invalid': errors.name }" />
							<div class="field grid px-2 py-2">
								<label
									for="sexe"
									class="col-12 mb-2 md:col md:mb-0"
									>Sexe</label
								>
								<div class="col-12 md:col flex flex-wrap gap-3" style="justify-content: end;">
									<div class="flex">
										<RadioButton
											inputId="male"
											name="Masculin"
											value="male"
											v-model="sex" />
										<label
											for="male"
											class="ml-2"
											>Masculin</label
										>
									</div>
									<div class="flex">
										<RadioButton
											inputId="female"
											name="Feminin"
											value="female"
											v-model="sex" />
										<label
											for="female"
											class="ml-2"
											>Feminin</label
										>
									</div>
									<small
										id="type-help"
										class="p-error">
										{{ errors.sex }}
									</small>
								</div>
							</div>
							<PInput
								label="Fonction de l'agent"
								v-model="agentFunction"
								:value="agentFunction"
								type="text"
								:class="{ 'p-invalid': errors.agentFunction }"
								field="agentFunction" />
						</div>
						<div class="field col">
							<PInput
								label="Téléphone de l'agent"
								v-model="mobile"
								:value="mobile"
								type='number'
								autocomplete='off' />
							<div class="flex py-0 my-0" style="justify-content: end;">
								<small
									id="type-help"
									class="p-error ">
									{{ errors.mobile }}
								</small>
							</div>
							<div class="field grid px-2">
								<label
									for="accoucheuse"
									class="col-12 mb-2 md:col md:mb-0"
									>Service</label
								>
								<div class="col-12 md:col" style="text-align: end">
									<AutoComplete 
										class="w-full" 
										placeholder="Selectionnez..." 
										id="id" 
										:dropdown="true" 
										:multiple="false" 
										v-model="service_select" 
										:suggestions="autoFilteredValue" 
										@complete="searchUser($event)" 
										field="name" />
									<small
										id="type-help"
										class="p-error ">
										{{ errors.service_id }}
									</small>
								</div>
							</div>
							<PInput
								v-if="update"
								label="Code secret"
								:value="secret_code"
								v-model="secret_code"
								disabled />
						</div>
					</div>
					<div
						class="mb-4 add-agent-btn-container"
						v-if="update">
						<Button
							class="cancel-btn-class mx-2"
							v-on:click="cancel">
							Annuler
						</Button>
						<Button
							class="add-agent-btn mx-2"
							@click="updateRequest">
							Modifier
						</Button>
					</div>
					<div
						class="mb-4 add-agent-btn-container"
						v-else>
						<Button
							class="cancel-btn-class mx-2"
							@click="cancel">
							Annuler
						</Button>
						<Button
							class="add-agent-btn mx-2"
							label="Ajouter"
							type="submit" />
					</div>
				</form>
			</div>
			<table-view
				:data="agentStore().getAgents"
				:columns="columns"
				:tableTitle="`Liste de agents`"
				v-on:declarationForm="declarationForm"
				v-on:declarationUpdate="declarationUpdate" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref, watch } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import { useToast } from "primevue/usetoast";

	const toast = useToast()

	const schema = yup.object({
		name: yup
			.string()
			.required("Le nom de l'agent est requis")
			.label("Nom complet"),
		agentFunction: yup
			.string()
			.required("La fonction de l'agent est requis")
			.label("Fonction de l'agent"),
		service_id: yup
			.string()
			.required("Veuillez choisir le service de l'agent")
			.label("Service"),
		mobile: yup
			.string()
			.required("Le téléphone de l'agent est requis")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Téléphone de l'agent"),
		sex: yup
			.string()
			.required("Veuillez choisir le sexe de l'agent")
			.label("Sexe"),
	});

	const { defineField, handleSubmit, resetForm, errors } = useForm({
		validationSchema: schema,
	});

	const [name] = defineField("name");
	const [agentFunction] = defineField("function");
	const [service_id] = defineField("service_id");
	const [mobile] = defineField("mobile");
	const [sex] = defineField("sex");
	const [secret_code] = defineField("secret_code");

	const service_select = ref({} as ServiceOption);
	watch(
		() => service_select.value,
		(newVal) => {
			service_id.value = newVal.id;
		}
	);

	const declaration = ref(false);
	const update = ref(false);

	const cancel = () => {
		declaration.value = false;
		update.value = false;

		name.value = "";
		agentFunction.value = "";
		service_id.value = "";
		mobile.value = "";
		sex.value = "male";
		secret_code.value = "";
		service_select.value = {}
	};
	const declarationForm = () => {
		declaration.value = !declaration.value;
		resetForm();
	};
	const declarationUpdate = () => {
		declaration.value = !declaration.value;
		update.value = !update.value;

		name.value = agentStore().getAgent.name;
		agentFunction.value = agentStore().getAgent.function;
		service_id.value = agentStore().getAgent.service_id;
		mobile.value = agentStore().getAgent.mobile;
		sex.value = agentStore().getAgent.sex || "male";
		secret_code.value = agentStore().getAgent.secret_code;
		const agent = espacePartenaireStore().services?.find(
            (element: Service) => element.service_id === agentStore().getAgent.service_id
        );
		service_select.value = {
			value: agent?.service_id,
			name: agent?.service_name,
			id: agent?.service_id
		}
	};

	const onSubmit = handleSubmit((values) => {
		const agent = {
			name: values.name,
			mobile: values.mobile.toString(),
			function: values.agentFunction,
			service_id: values.service_id,
			sex: values.sex,
			health_center_code: useAuthStore().getUserInfo.health_center_code,
		} as unknown as Agent;
		agentStore().setLoading();
		agentStore().addAgent(agent).then((res) => {
			if (res) {
				toast.add({ severity: 'success', summary: 'Message', detail: "Ajout du nouvel agent effectué avec succès!", life: 8000 });
			} else
				toast.add({ severity: 'error', summary: 'Message', detail: "Erreur lors de l'ajout du nouvel agent", life: 8000 });
		})
		setTimeout(() => {
			cancel();
		}, 100);
	});

	const updateRequest = () => {
		agentStore().setLoading();
		const agent = {
			name: name.value,
			mobile: mobile.value.toString(),
			function: agentFunction.value,
			service_id: service_id.value,
			sex: sex.value,
			health_center_code: useAuthStore().getUserInfo.health_center_code,
			agent_id: agentStore().getAgent.agent_id,
		} as unknown as Agent;
		agentStore().updateAgent(agent).then((res) => {
			if (res) {
				toast.add({ severity: 'success', summary: 'Message', detail: "Mise à jour effectuée avec succès!", life: 8000 });
			} else
				toast.add({ severity: 'error', summary: 'Message', detail: "Erreur lors de la mise à jour", life: 8000 });
		})
		setTimeout(() => {
			cancel();
		}, 100);
	};
</script>

<script lang="ts">
	import { defineComponent } from "vue";
	import TableFilter from "@/components/table/table-filter/table-filter.vue";
	import TableView from "@/components/table/table-view/table-view.vue";
	import PInput from "@/components/forms/p-input.vue";
	import { Agent } from "@/models/agent/agent";
	import { agentStore } from "@/store/modules/agent";	
	import { useAuthStore } from "@/store/modules/auth";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { Service, ServiceOption } from "@/models/service/service";
	export default defineComponent({
		name: "AgentsPage",
		components: {
			TableFilter,
			TableView,
			PInput,
		},
		data() {
			return {
				filter: "",
				columns: [
					{ field: "name", header: "Nom complet" },
					{ field: "function", header: "Service" },
					{ field: "created_at", header: "Date MAJ" },
					{ field: "actions", header: "Actions" },
				],
				autoFilteredValue: [] as Array<object>,
				agentStore: agentStore(),
				authStore: useAuthStore(),
				partnerStore: espacePartenaireStore(),
			};
		},
		methods: {
			searchUser(event) {
				if (!event.query.trim().length) {
					this.autoFilteredValue = [...this.partnerStore.serviceOptions];
				} else {
					this.autoFilteredValue = this.partnerStore.serviceOptions.filter((user) => {
						return user.name.toLowerCase().includes(event.query.toLowerCase());
					});
				}
			},
			searchText(searchValue: string) {
				this.filter = searchValue;
			},
		},
		beforeMount() {
			this.agentStore.loadAgents();
			this.partnerStore.loadServices()
		},
	});
</script>

<style lang="scss" src="./agents-page.scss" scoped />
