import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/hospital.png'


const _hoisted_1 = {
  class: "container",
  style: {"background-color":"white"}
}
const _hoisted_2 = { class: "grid align-items-center" }
const _hoisted_3 = { class: "sm:col-12 md:col-6 lg:col-6 border-left pl-4" }
const _hoisted_4 = { class: "container-login text-center pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sm:col-12 md:col-6 lg:col-6 page-image-container" }, [
        _createElementVNode("img", {
          class: "img-fluid page-image",
          src: _imports_0,
          alt: "Hospital Image"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-center text-uppercase font-weight-bold welcome-text" }, " Bienvenue sur Hospi-Connect ", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-center" }, " Ce site est exclusivement réservé aux centres de santé affiliés à la télédéclaration ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            class: "p-button p-button-rounded submit-btn justify-content-center",
            onClick: _withModifiers(_ctx.onClickLogin, ["prevent"])
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", null, "Se connecter", -1)
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}