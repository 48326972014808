import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "agents-page" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_3 = { class: "my-3" }
const _hoisted_4 = {
  key: 1,
  class: "form-section mb-3"
}
const _hoisted_5 = { class: "formgrid grid mx-2 mb-0 mt-4" }
const _hoisted_6 = { class: "field col" }
const _hoisted_7 = { class: "field grid px-2 py-2" }
const _hoisted_8 = {
  class: "col-12 md:col flex flex-wrap gap-3",
  style: {"justify-content":"end"}
}
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = {
  class: "flex py-0 my-0",
  style: {"justify-content":"end"}
}
const _hoisted_14 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_15 = { class: "field grid px-2" }
const _hoisted_16 = {
  class: "col-12 md:col",
  style: {"text-align":"end"}
}
const _hoisted_17 = {
  id: "type-help",
  class: "p-error"
}
const _hoisted_18 = {
  key: 0,
  class: "mb-4 add-agent-btn-container"
}
const _hoisted_19 = {
  key: 1,
  class: "mb-4 add-agent-btn-container"
}

import { ref, watch } from "vue";
	import { useForm } from "vee-validate";
	import * as yup from "yup";
	import { useToast } from "primevue/usetoast";

	
	import { defineComponent } from "vue";
	import TableFilter from "@/components/table/table-filter/table-filter.vue";
	import TableView from "@/components/table/table-view/table-view.vue";
	import PInput from "@/components/forms/p-input.vue";
	import { Agent } from "@/models/agent/agent";
	import { agentStore } from "@/store/modules/agent";	
	import { useAuthStore } from "@/store/modules/auth";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { Service, ServiceOption } from "@/models/service/service";
	const __default__ = defineComponent({
		name: "AgentsPage",
		components: {
			TableFilter,
			TableView,
			PInput,
		},
		data() {
			return {
				filter: "",
				columns: [
					{ field: "name", header: "Nom complet" },
					{ field: "function", header: "Service" },
					{ field: "created_at", header: "Date MAJ" },
					{ field: "actions", header: "Actions" },
				],
				autoFilteredValue: [] as Array<object>,
				agentStore: agentStore(),
				authStore: useAuthStore(),
				partnerStore: espacePartenaireStore(),
			};
		},
		methods: {
			searchUser(event) {
				if (!event.query.trim().length) {
					this.autoFilteredValue = [...this.partnerStore.serviceOptions];
				} else {
					this.autoFilteredValue = this.partnerStore.serviceOptions.filter((user) => {
						return user.name.toLowerCase().includes(event.query.toLowerCase());
					});
				}
			},
			searchText(searchValue: string) {
				this.filter = searchValue;
			},
		},
		beforeMount() {
			this.agentStore.loadAgents();
			this.partnerStore.loadServices()
		},
	});


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

	const toast = useToast()

	const schema = yup.object({
		name: yup
			.string()
			.required("Le nom de l'agent est requis")
			.label("Nom complet"),
		agentFunction: yup
			.string()
			.required("La fonction de l'agent est requis")
			.label("Fonction de l'agent"),
		service_id: yup
			.string()
			.required("Veuillez choisir le service de l'agent")
			.label("Service"),
		mobile: yup
			.string()
			.required("Le téléphone de l'agent est requis")
			.matches(
				/^[0-9]{8}$/,
				"Le numéro de téléphone doit contenir exactement 8 chiffres"
			)
			.label("Téléphone de l'agent"),
		sex: yup
			.string()
			.required("Veuillez choisir le sexe de l'agent")
			.label("Sexe"),
	});

	const { defineField, handleSubmit, resetForm, errors } = useForm({
		validationSchema: schema,
	});

	const [name] = defineField("name");
	const [agentFunction] = defineField("function");
	const [service_id] = defineField("service_id");
	const [mobile] = defineField("mobile");
	const [sex] = defineField("sex");
	const [secret_code] = defineField("secret_code");

	const service_select = ref({} as ServiceOption);
	watch(
		() => service_select.value,
		(newVal) => {
			service_id.value = newVal.id;
		}
	);

	const declaration = ref(false);
	const update = ref(false);

	const cancel = () => {
		declaration.value = false;
		update.value = false;

		name.value = "";
		agentFunction.value = "";
		service_id.value = "";
		mobile.value = "";
		sex.value = "male";
		secret_code.value = "";
		service_select.value = {}
	};
	const declarationForm = () => {
		declaration.value = !declaration.value;
		resetForm();
	};
	const declarationUpdate = () => {
		declaration.value = !declaration.value;
		update.value = !update.value;

		name.value = agentStore().getAgent.name;
		agentFunction.value = agentStore().getAgent.function;
		service_id.value = agentStore().getAgent.service_id;
		mobile.value = agentStore().getAgent.mobile;
		sex.value = agentStore().getAgent.sex || "male";
		secret_code.value = agentStore().getAgent.secret_code;
		const agent = espacePartenaireStore().services?.find(
            (element: Service) => element.service_id === agentStore().getAgent.service_id
        );
		service_select.value = {
			value: agent?.service_id,
			name: agent?.service_name,
			id: agent?.service_id
		}
	};

	const onSubmit = handleSubmit((values) => {
		const agent = {
			name: values.name,
			mobile: values.mobile.toString(),
			function: values.agentFunction,
			service_id: values.service_id,
			sex: values.sex,
			health_center_code: useAuthStore().getUserInfo.health_center_code,
		} as unknown as Agent;
		agentStore().setLoading();
		agentStore().addAgent(agent).then((res) => {
			if (res) {
				toast.add({ severity: 'success', summary: 'Message', detail: "Ajout du nouvel agent effectué avec succès!", life: 8000 });
			} else
				toast.add({ severity: 'error', summary: 'Message', detail: "Erreur lors de l'ajout du nouvel agent", life: 8000 });
		})
		setTimeout(() => {
			cancel();
		}, 100);
	});

	const updateRequest = () => {
		agentStore().setLoading();
		const agent = {
			name: name.value,
			mobile: mobile.value.toString(),
			function: agentFunction.value,
			service_id: service_id.value,
			sex: sex.value,
			health_center_code: useAuthStore().getUserInfo.health_center_code,
			agent_id: agentStore().getAgent.agent_id,
		} as unknown as Agent;
		agentStore().updateAgent(agent).then((res) => {
			if (res) {
				toast.add({ severity: 'success', summary: 'Message', detail: "Mise à jour effectuée avec succès!", life: 8000 });
			} else
				toast.add({ severity: 'error', summary: 'Message', detail: "Erreur lors de la mise à jour", life: 8000 });
		})
		setTimeout(() => {
			cancel();
		}, 100);
	};

return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toast),
    _createElementVNode("div", null, [
      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "title-padding m-0" }, [
        _createElementVNode("h1", { class: "espace-header" }, "Déclaration et liste des agents")
      ], -1)),
      (_unref(agentStore)().getLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"50px","height":"50px"},
              strokeWidth: "5",
              fill: "var(--surface-ground)",
              animationDuration: ".5s",
              "aria-label": "Custom ProgressSpinner"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(TableFilter, {
          canCreate: false,
          onSearchText: _ctx.searchText,
          onDeclarationForm: declarationForm,
          name: "un agent"
        }, null, 8, ["onSearchText"])
      ]),
      (declaration.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("form", {
              onSubmit: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(PInput, {
                    label: "Nom complet",
                    modelValue: _unref(name),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(name) ? (name).value = $event : null)),
                    value: _unref(name),
                    type: "text",
                    class: _normalizeClass({ 'p-invalid': _unref(errors).name })
                  }, null, 8, ["modelValue", "value", "class"]),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", {
                      for: "sexe",
                      class: "col-12 mb-2 md:col md:mb-0"
                    }, "Sexe", -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_RadioButton, {
                          inputId: "male",
                          name: "Masculin",
                          value: "male",
                          modelValue: _unref(sex),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(sex) ? (sex).value = $event : null))
                        }, null, 8, ["modelValue"]),
                        _cache[9] || (_cache[9] = _createElementVNode("label", {
                          for: "male",
                          class: "ml-2"
                        }, "Masculin", -1))
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_RadioButton, {
                          inputId: "female",
                          name: "Feminin",
                          value: "female",
                          modelValue: _unref(sex),
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(sex) ? (sex).value = $event : null))
                        }, null, 8, ["modelValue"]),
                        _cache[10] || (_cache[10] = _createElementVNode("label", {
                          for: "female",
                          class: "ml-2"
                        }, "Feminin", -1))
                      ]),
                      _createElementVNode("small", _hoisted_11, _toDisplayString(_unref(errors).sex), 1)
                    ])
                  ]),
                  _createVNode(PInput, {
                    label: "Fonction de l'agent",
                    modelValue: _unref(agentFunction),
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(agentFunction) ? (agentFunction).value = $event : null)),
                    value: _unref(agentFunction),
                    type: "text",
                    class: _normalizeClass({ 'p-invalid': _unref(errors).agentFunction }),
                    field: "agentFunction"
                  }, null, 8, ["modelValue", "value", "class"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(PInput, {
                    label: "Téléphone de l'agent",
                    modelValue: _unref(mobile),
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(mobile) ? (mobile).value = $event : null)),
                    value: _unref(mobile),
                    type: "number",
                    autocomplete: "off"
                  }, null, 8, ["modelValue", "value"]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("small", _hoisted_14, _toDisplayString(_unref(errors).mobile), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", {
                      for: "accoucheuse",
                      class: "col-12 mb-2 md:col md:mb-0"
                    }, "Service", -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_AutoComplete, {
                        class: "w-full",
                        placeholder: "Selectionnez...",
                        id: "id",
                        dropdown: true,
                        multiple: false,
                        modelValue: service_select.value,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((service_select).value = $event)),
                        suggestions: _ctx.autoFilteredValue,
                        onComplete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.searchUser($event))),
                        field: "name"
                      }, null, 8, ["modelValue", "suggestions"]),
                      _createElementVNode("small", _hoisted_17, _toDisplayString(_unref(errors).service_id), 1)
                    ])
                  ]),
                  (update.value)
                    ? (_openBlock(), _createBlock(PInput, {
                        key: 0,
                        label: "Code secret",
                        value: _unref(secret_code),
                        modelValue: _unref(secret_code),
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(secret_code) ? (secret_code).value = $event : null)),
                        disabled: ""
                      }, null, 8, ["value", "modelValue"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              (update.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createVNode(_component_Button, {
                      class: "cancel-btn-class mx-2",
                      onClick: cancel
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode(" Annuler ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_Button, {
                      class: "add-agent-btn mx-2",
                      onClick: updateRequest
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode(" Modifier ")
                      ])),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_Button, {
                      class: "cancel-btn-class mx-2",
                      onClick: cancel
                    }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" Annuler ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_Button, {
                      class: "add-agent-btn mx-2",
                      label: "Ajouter",
                      type: "submit"
                    })
                  ]))
            ], 32)
          ]))
        : _createCommentVNode("", true),
      _createVNode(TableView, {
        data: _unref(agentStore)().getAgents,
        columns: _ctx.columns,
        tableTitle: `Liste de agents`,
        onDeclarationForm: declarationForm,
        onDeclarationUpdate: declarationUpdate
      }, null, 8, ["data", "columns"])
    ])
  ]))
}
}

})