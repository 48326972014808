import { HttpBaseService } from '@/services/httpBase.service';
import { ApiResponse } from '@/models/http/ApiResponse';

export class TemplateService extends HttpBaseService {
  private static classInstance?: TemplateService;
  static apiBaseUrl = 'https://api-document-generator.nathos.dev/api/v1/document-generator';

  constructor(token: string) {
    super(token, TemplateService.apiBaseUrl);
  }

  public static getInstance(token: string): TemplateService {
    if (!this.classInstance) {
      this.classInstance = new TemplateService(token);
    }

    return this.classInstance;
  }

  public getInstancePrintPage(payload: string): Promise<ApiResponse<string>> {
    const apiResponse = new ApiResponse<string>();
    return this.instance
      .get(`/template/get?template_id=${payload}`)
      .then((response) => {
        apiResponse.data = response.data.html_content;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response;
        return apiResponse;
      });
  }
}