<template>
	<div
		class="container"
		style="background-color: white;">
		<div class="grid align-items-center">
			<div class="sm:col-12 md:col-6 lg:col-6 page-image-container">
				<img
					class="img-fluid page-image"
					src="../../assets/hospital.png"
					alt="Hospital Image" />
			</div>
			<div class="sm:col-12 md:col-6 lg:col-6 border-left pl-4">
				<div class="text-center text-uppercase font-weight-bold welcome-text">
					Bienvenue sur Hospi-Connect
				</div>
				<br />
				<p class="text-center">
					Ce site est exclusivement réservé aux centres de santé affiliés à la
					télédéclaration
				</p>
				<div class="container-login text-center pb-5">
					<Button
						class="p-button p-button-rounded submit-btn justify-content-center"
						@click.prevent="onClickLogin">
						<span>Se connecter</span>
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import { useAuthStore } from "@/store/modules/auth";

	export default defineComponent({
		name: "LoginPage",

		data() {
			return {
				authStore: useAuthStore(),
			};
		},

		methods: {
      onClickLogin() {
				(this as any).$router.push({ path: "/main" });
			},
		},
	});
</script>

<style src="./login-page.scss" lang="scss" scoped />
