import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-4"
}

import { ref } from "vue";

	
    import { defineComponent } from 'vue';

    const __default__ = defineComponent({
        props: ["path", "name"],
	})

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'table-filter',
  emits: ["searchText", "declarationForm"],
  setup(__props, { emit: __emit }) {

	const emits = __emit;

	const declarationForm = ref(false);

	const declaration = () => {
		emits("declarationForm", !declarationForm.value);
		declarationForm.value = !declarationForm.value;
	};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        (_ctx.path)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Button, {
                class: "files-btn-class",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ path: _ctx.path })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Déclarer " + _toDisplayString(_ctx.name), 1)
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Button, {
                class: "files-btn-class",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (declaration()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Déclarer " + _toDisplayString(_ctx.name), 1)
                ]),
                _: 1
              })
            ]))
      ]),
      _: 1
    })
  ]))
}
}

})